import React from "react";
import PropTypes from "prop-types";
import { Heading, Text, Badge } from "theme-ui";

const styles = {
    count: {
        fontSize: 4,
    },
    subheader: {
        fontWeight: `body`,
        color: `omegaDark`,
        textAlign: `center`,
        marginBottom: 0,
    },
    runninghead: {
        fontWeight: `body`,
        color: `omegaDark`,
        mb: 0,
    },
    heading: {
        fontSize: `32px`,
        textAlign: `center`,
    },
};

const PageTitle = ({ header, subheader, running, totalCount }) => {
    return (
        <div style={{ width: `100%` }}>
            <Heading variant="h2" sx={styles.heading}>
                {header}{" "}
                {totalCount && (
                    <Badge variant="tag-white" sx={styles.count}>
                        {" "}
                        {totalCount}
                    </Badge>
                )}
            </Heading>
            {subheader && (
                <Text variant="h3" sx={styles.subheader}>
                    {subheader}
                </Text>
            )}
            {running && (
                <Text variant="h4" sx={styles.runninghead}>
                    {running}
                </Text>
            )}
        </div>
    );
};

export default PageTitle;

PageTitle.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    running: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    totalCount: PropTypes.number,
};
